import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import { type WidgetDragResizeState } from "legacy/reducers/uiReducers/dragResizeReducer";
import { getWidgets, getWidget } from "./entitiesSelector";
import type { AppState } from "store/types";

type Result =
  | {
      shouldExpand: true;
      dropTargetId: string;
      dropTargetRows: number;
    }
  | {
      shouldExpand: false;
    };

const NotDragging: Result = Object.freeze({
  shouldExpand: false,
});

export const selectIsDragging = (state: AppState) =>
  state.legacy.ui.widgetDragResize.isDragging;

export const selectIsResizing = (state: AppState) =>
  state.legacy.ui.widgetDragResize.isResizing;

const selectCurrentDropTarget = (state: AppState) =>
  state.legacy.ui.widgetDragResize.currentDropTarget;

export const selectResizingItemAutoHeight = (state: AppState) =>
  state.legacy.ui.widgetDragResize.isResizing &&
  state.legacy.ui.widgetDragResize.focusedWidgetId &&
  state.legacy.entities.canvasWidgets[
    state.legacy.ui.widgetDragResize.focusedWidgetId
  ]?.height?.mode === "fitContent";

export const isDraggingInContainer = createCachedSelector(
  (state: AppState) =>
    state.legacy.ui.widgetDragResize.isDragging ||
    (state.legacy.ui.widgetDragResize.isResizing &&
      !selectResizingItemAutoHeight(state)),
  selectCurrentDropTarget,
  (state: AppState) =>
    state.legacy.ui.widgetDragResize.currentDropTargetRows ?? 1,
  getWidget,
  (isDragging, dropTargetId, dropTargetRows, widget): Result => {
    if (!isDragging) return NotDragging;
    if (!dropTargetId) return NotDragging;

    const isDropTarget = dropTargetId && dropTargetId === widget?.widgetId;
    if (isDropTarget) {
      return {
        shouldExpand: true,
        dropTargetId,
        dropTargetRows,
      };
    }

    const isAChildOfDropTarget = widget?.children?.includes(dropTargetId);
    if (isAChildOfDropTarget) {
      return {
        shouldExpand: true,
        dropTargetId,
        dropTargetRows,
      };
    }
    return NotDragging;
  },
)((_state, widgetId) => widgetId);

export const getResizingStackWidgetWidth = createSelector(
  (state: AppState) => state.legacy.ui.widgetDragResize,
  (widgetDragResize: WidgetDragResizeState) => {
    if (
      !widgetDragResize?.isResizing ||
      !widgetDragResize?.selectedWidgets?.length ||
      !widgetDragResize.currentDropTarget ||
      widgetDragResize.resizingStackWidth == null
    )
      return undefined;
    const resizingWidget = {
      canvasId: widgetDragResize.currentDropTarget,
      width: widgetDragResize.resizingStackWidth,
    };
    return resizingWidget;
  },
);

export const isChildADropTarget = createCachedSelector(
  selectCurrentDropTarget,
  getWidgets,
  (_: AppState, widgetId: string) => widgetId,
  (currentDropTargetId, widgets, widgetId) => {
    if (!currentDropTargetId) return false;
    const widget = widgets[widgetId];
    if (!widget) return false;
    return widget.children?.includes(currentDropTargetId) || false;
  },
)((_: AppState, widgetId: string) => widgetId);
