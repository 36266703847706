import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentPageId } from "legacy/selectors/editorSelectors";
import { getCurrentApplicationId } from "legacy/selectors/editorSelectors";
import { useAppDispatch } from "store/helpers";
import { extractScopeAndVariableNameFromKey } from "store/slices/application/stateVars/StateConstants";
import { setStateVarValue } from "store/slices/application/stateVarsMeta/stateVarsMetaActions";
import logger from "utils/logger";

export function useSyncLocalStorageStateVars(): void {
  const dispatch = useAppDispatch();

  const appId = useSelector(getCurrentApplicationId) ?? "";
  const pageId = useSelector(getCurrentPageId) ?? "";

  useEffect((): (() => void) => {
    const handleStateVariableUpdated = (storageEvent: StorageEvent) => {
      const {
        key,
        newValue: unparsedNewValue,
        oldValue: unparsedOldValue,
      } = storageEvent;
      if (!key || unparsedNewValue === unparsedOldValue) return;
      try {
        const resp = extractScopeAndVariableNameFromKey(key, appId, pageId);
        if (!resp) return;
        const newValue = unparsedNewValue ? JSON.parse(unparsedNewValue) : null;
        const { scope, stateVarId } = resp;
        // skipPersist `true` because we don't want to get into a potential infinite loop if many tabs are trying to set
        dispatch(setStateVarValue(scope, stateVarId, newValue, true));
      } catch (e: any) {
        logger.warn(`Error parsing localStorage key "${key}"`, e);
      }
    };

    window.addEventListener("storage", handleStateVariableUpdated);

    return (): void => {
      window.removeEventListener("storage", handleStateVariableUpdated);
    };
  }, [dispatch, appId, pageId]);
}
